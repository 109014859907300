@media screen and (max-width: 340px) {
  .cc-floating.cc-theme-classic {
    padding: .55em .9em !important;
  }

  .cc-message {
    font-size: 11px;
    line-height: 15px;
    margin-bottom: .6em !important;
  }

  .cc-btn {
    padding: .1em .8em !important;
  }
}

@media screen and (min-width: 340px) and (max-width: 560px) {
  .cc-floating.cc-theme-classic {
    padding: .55em .9em !important;
  }

  .cc-message {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: .6em !important;
  }

  .cc-btn {
    padding: .1em .8em !important;
  }
}
