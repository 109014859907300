.user-entity-view {
  min-width: 66%;

  &-container {
    box-sizing: border-box;
    padding: 47px 37px 37px 40px;
    border-radius: 5px;
    border: 0;
    background-color: #fff;
    box-shadow: 0 2px 3px -1px #dcdde2;
    position: relative;

    &_documents {
      margin-top: 20px;
      padding: 47px 37px 37px 18px;
    }

    &_description {
      margin-top: 20px;
      padding: 47px 37px 37px 18px;
      &__content {
        word-break: break-all;
        white-space: pre;
        margin-left: 22px;
        overflow: auto;
      }
    }
  }

  &-options {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  &__info {
    margin-left: 22px;
  }

  &-info {
    display: block;
  }
  &-item {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    &_full {
      width: 100%;
    }
    &__label {
      width: 200px;
    }
    &__value {
      font-weight: bold;
    }
  }

  &-title {
    font-size: 24px;
    font-weight: 400;
    padding: 0 22px 0 22px;
    margin-bottom: 30px;

    &__nopad {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 30px;
    }
  }

  .table-container {
    position: relative;
    width: 100%;
  }

  .table-container .mat-mdc-table {
    font-family: var(--root-font-family);
  }

  .table-container table {
    width: 100%;
  }

  .loading-shade {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 56px;
    right: 0;
    background: rgba(0, 0, 0, 0.10);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table-container .mat-mdc-header-cell {
    font-size: 15px;
    font-weight: 600;
    color: #2f3542;
  }

  .file-link a {
    font-weight: bold;
    color: #1b75ba;
  }
}

@media screen and (max-width: 960px) {
  .user-entity-view {

    &-container { padding: 15px;
      &_description {
        padding: 15px;
        &__content {
          margin-left: 0;
        }
      }
    }

    &__info {
      margin-left: 0px;
    }

    &-title {
      padding: 0;
      margin-bottom: 15px;
    }

    &-item {
      display: block;
      width: 100%;
      margin-bottom: 20px;
      &__label {
        width: 100%;
      }
    }
  }
}
