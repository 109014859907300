@import "../../app/style/_breakpoints.scss";
.registration-input {
  position: relative;
  input,select {
    padding-left: 10px;
    padding-bottom: 2px;
    &:focus {
      padding-bottom: 0;
      outline: none;
      border-bottom: 3px solid #b3ccf8;
    }
  }
  label {
    position: relative;
    app-form-descriptor {
      display: inline;
      position: absolute;
      right: 10px;
      top: 0;
    }
  }
  &.label-not-capitalize {
    label {
      text-transform: none;
    }
  }
}
.registration-text-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  &.no-label {
    margin-top: 35px;
  }
  &.fill {
    flex-grow: 1;
  }
}

.registration-text-input input {
  width: 100%;
  height: 44px;
  border: none;
  border-bottom: 1px solid #ced6e0;
  color: #2f3542;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  padding-top: 0px;
  box-sizing: border-box;
}

.registration-text-input label {
  width: 100%;
  font-weight: 500;
  color: #2f3542;
  z-index: 3;
  text-transform: capitalize;
}

.optional-text-input input::placeholder {
  color: #b5b7ba;
  font-size: 16px;
  font-weight: 400;
}

.registration-date-input {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.registration-select-input {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.registration-select-input select {
  width: 100%;
  height: 44px;
  border: none;
  border-bottom: 1px solid #ced6e0;
  color: #2f3542;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  padding-top: 0px;
  box-sizing: border-box;
  appearance: none;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.registration-select-input label {
  width: 100%;
  font-weight: 500;
  color: #2f3542;
  z-index: 3;
  text-transform: capitalize;
}

input.is-invalid-i,
.text-input input.is-invalid-i,
.registration-select-input select.is-invalid-i {
  border-bottom: 1px solid red;
}
.text-input input.is-invalid-i_full,
.text-input select.is-invalid-i_full,
.text-input textarea.is-invalid-i_full,
.user-entity-form .text-input textarea.is-invalid-i_full,
.user-entity-form .text-input select.is-invalid-i_full,
.registration-select-input select.is-invalid-i {
  border: 1px solid red;
}

.invalid-input-dedactible{
  font-size: 12px;
  color: red;
}

.registration-input-folded,
.registration-input-detailed {
  position: absolute;
  top: 55px;
  right: 10px;
  width: 15px;
  z-index: 2;
}


.registration-date-input input {
  width: 100%;
  height: 44px;
  border: none;
  border-bottom: 1px solid #ced6e0;
  color: #2f3542;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  padding-top: 0px;
  box-sizing: border-box;
}

.registration-date-input label {
  width: 100%;
  font-weight: 500;
  color: #2f3542;
  z-index: 3;
  text-transform: capitalize;
}

.required-label {
  &::after {
    content: '*';
    color: red;
  }
}

mat-mdc-checkbox.required-label {
  &::after {
    content: '';
    color: red;
  }
  .mat-mdc-checkbox-label::after {
    content: '*';
    color: red;
    margin-left: 3px;
  }
}

.datapicker-input_mat{
  position: absolute;
  top: 35px;
  right: 3px;
}

.form-heading {
  width: 100%;
  font-size: 20px;
  text-align: center;
}

.form-subheading {
  width: 100%;
  text-align: center;
  font-size: 18px;
}

.additional-information-label {
  width: 100%;
  font-size: 12px;
  color: grey;
}

.mrt {
  input:before {
    content: '$'
  }
}

.info-icon-block {
  position: absolute;
  top: 0;
  left: -35px;
  width: 25px;
  height: 25px;
}

.info-checkbox {
  margin: 15px 0;
  padding-left: 35px;
  box-sizing: border-box;
}

.info-checkbox label {
  position: relative;
  cursor: pointer;
  display: block;
}

.info-checkbox input {
  display: none;
}

.info-checkbox-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 30px;
}

.btn {
  display: inline-flex;
  border: 1px solid grey;
  height: 54px;
  line-height: 54px;
  padding: 0 15px;
  color: gray;
  justify-content: center;
  border-radius: 5px;

  &.cancel-btn {
    background: #fff;
    font-size: 18px;
  }
}

.registration-input-options {
  li {
    cursor: pointer;
  }
}


.mat-field-loading-spinner {
  margin-left: 5px;
  margin-right: 15px;
  display: flex;

  mat-spinner {
    margin-right: 10px;
  }
}

.mat-mdc-form-field-prefix {
  top: 0 !important;
}

.error-row {
  font-size: 13.5px;
  margin-bottom: 12px;
}
