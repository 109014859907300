app-main-confirm { width: 100%; }

.main-confirm {
  padding-top: 25px;
  width: 100%;
  position: relative;
  background: #fff;

  &__back {
    position: absolute;
    z-index: 1;
    top: 50px;
    background-repeat: no-repeat;
    background-size: auto 100%;
    opacity: .4;
    &_left {
      left: 0;
      height: 420px;
      width: 600px;
      background-position-x: 90%;
      background-position-y: bottom;
      background-image: url('../../../assets/images/residential-left.svg');
    }
    &_right {
      right: 0;
      height: 420px;
      width: 600px;
      background-position-x: 0;
      background-position-y: bottom;
      background-image: url('../../../assets/images/residential-right.svg');
    }
  }

  &__content {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 150px;
    width: 250px;
    text-align: center;
    &__title {
      color: #000;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.33px;
      line-height: 19px;
    }
    &__text {
      margin-top: 6px;
      color: #000;
      font-size: 12px;
      letter-spacing: 0.14px;
      line-height: 17px;
    }
  }

  &-dialog {
    position: relative;
    z-index: 2;
    margin: 53px auto 123px;
    padding: 34px 53px 62px;
    box-sizing: border-box;
    width: 100%;
    max-width: 610px;
    background-color: #F1F2F6;
    box-shadow: 0 19px 19px -1px rgba(0,0,0,0.5);

    &__title {
      color: var(--secondary-color);
      font-size: 28px;
      font-weight: 600;
      letter-spacing: -0.1px;
      line-height: 38px;

      &_highlight {
        color: var(--primary-color);
      }
    }

    &__content {
      margin-top: 46px;
      color: #040404;
      font-size: 14px;
      letter-spacing: 0.33px;
      line-height: 25px;

      li {
        &::before {
          display: inline-block;
          margin-right: 10px;
          content: '\2022'
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 57px;

      .mat-btn {
        width: 100%;
        &+.mat-btn {
          margin-left: 10px;
        }
      }
    }

    &__btn {
      border-radius: 2px;
      color: #fff;
      padding: 10px 0;
      box-sizing: border-box;
      border: 1px solid var(--warning-color);
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.69px;
      line-height: 18px;
      background-color: var(--warning-color);
      width: calc(50% - 7px);

      &_outline {
        background-color: rgba(0, 0, 0, 0);
        color: var(--secondary-color);
        border: 1px solid var(--secondary-color);
      }
    }
  }
}

@media only screen and (max-width: 1099px) {
  .main-confirm {
    &__back, &__content { display: none; }
    &-dialog {
      margin: 0 auto 30px;
      padding: 0 15px 51px;

      &__title { background: #fff; margin: 0 -15px; padding: 15px; }

      &__content { margin-top: 16px; }
    }
  }
}

@media only screen and (max-width: 413px) {
  .main-confirm {
    &-dialog {
      &__buttons {
        display: flex;
        flex-direction: column;
        margin-top: 17px;
        .mat-btn {
          width: 100%;
          &+.mat-btn {
            margin-top: 5px;
            margin-left: 0;
          }
        }
      }
      &__btn { width: 100%; margin-top: 17px; }
    }
  }
}

