.total-premium-invoices-accordion {
  &__title { padding-bottom: 4px; }

  .mat-expansion-panel-header {
    padding: 0 20px;
    font-family: var(--root-font-family);
  }

  .mat-expansion-panel-body {
    padding: 0;
    font-family: var(--root-font-family);
  }
}
