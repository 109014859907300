.main-content_freeway {

  .registration-input {
    label {
      font-size: 15px;
      margin-left: 5px;
      line-height: 21px;
    }

    input, select, textarea {
      @include Freeway-Input;
    }

    .mat-mdc-icon-button { margin-top: -2px; }

    .registration-input-folded { top: 50px; }

    input.is-invalid-i {
      border: 1px solid #CA0000;
    }
  }

  .invalid-input {
    font-size: 16px;
    line-height: 22px;
    color: #CA0000;
  }

  hr { margin: 15px 0; }

}
