.privacy-policy {

  font-size: 14px;

  h2{
    padding: 5px;
  }
  .text-ul-li {
    list-style-type: circle;
    padding-left: 10px;
  }
  p {
    padding: 2px;
    text-align: justify;
  }
  li {
    padding: 5px;
  }
}
