@mixin Freeway-Button-Disabled {
  border-color: rgba(0, 0, 0, .12);
  background-color: rgba(0, 0, 0, .12);
}

@mixin Freeway-Button-Style {
  //padding: 16px 22px;
  //font-size: 24px;
  padding: 8px 32px;
  font-size: 16px;
  letter-spacing: 0.38px;
  line-height: 30px;
  border-radius: 30px;
  font-family: var(--root-font-family);
  font-weight: 600;
  text-align: center;
}

@mixin Freeway-Button {
  @include Freeway-Button-Style;
  color: #fff;
  border: 1px solid var(--warning-color);
  background-color: var(--warning-color);
  &:focus {
    outline-color: #F2994A;
  }
  &:disabled {
    @include Freeway-Button-Disabled;
  }
}

@mixin Freeway-Input {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 16px;
  font-family: var(--root-font-family);
  font-weight: 400;
  padding: 0 15px;
  background-color: #fff;
  color: #000;
  letter-spacing: 0.26px;
  line-height: 21px;

  &::placeholder {
    font-size: 14px;
    font-weight: 100;
    letter-spacing: 0.22px;
  }
  &:disabled {
    background-color: #e9ecef;
    color: #495057;
    border: 1px solid #495057;
  }
  &:focus {
    border: 1px solid var(--border-color);
    outline: none;
  }
}
