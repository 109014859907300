.header-logo-wrap {
  width: 250px;
  height: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    position: absolute;
    top: 10px;
    left: calc(50% - 125px);
    height: 120px;
  }
}

.header-dual {
  position: relative;
  width: 25px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  &-container {
    position: absolute;
    top: 10px;
    left: calc(50% - 12.5px);
  }

  &-plus {
    width: 25px;
    height: 25px;
  }

  &-first {
    position: absolute;
    top: 0;
    left: -230px;
    width: 220px;
    height: 80px;
    display: flex;
    justify-content: end;
    align-items: center;
    img {
      height: 100%;
      width: auto;
      max-width: 100%;
      object-fit: contain;
    }
    &-square {
      width: 140px;
      left: -150px;
    }
  }

  &-second {
    position: absolute;
    top: 0;
    right: -230px;
    width: 220px;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    img {
      height: 100%;
      width: auto;
      max-width: 100%;
      object-fit: contain;
    }
    &-square {
      width: 140px;
      right: -150px;
    }
  }
}

/* Wide logo */
.header-logo-wrap__container-wide {
  left: calc(50% - 250px);

  .header-logo-wrap {
    width: 500px;
    max-width: 500px;
    height: 120px;
  }
}

@media screen and (max-width: 1279px) {
  .header-logo-wrap {
    max-width: 200px;
    height: 100px;

    &__container {
      height: 100px;
      left: calc(50% - 100px);
    }
  }

  .header-dual {
    width: 20px;
    height: 70px;

    &-container {
      top: 20px;
      left: calc(50% - 10px);
    }

    &-plus {
      width: 20px;
      height: 20px;
    }

    &-first {
      left: -180px;
      width: 170px;
      height: 70px;
      &-square {
        width: 90px;
        left: -100px;
      }
    }

    &-second {
      right: -180px;
      width: 170px;
      height: 70px;
      &-square {
        width: 00px;
        right: -100px;
      }
    }
  }
}


@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .header-logo-wrap__container-wide {
    height: 100px;
    left: calc(50% - 200px);

    .header-logo-wrap {
      max-width: 400px;
      height: 100px;
    }
  }
}

@media screen and (min-width: 870px) and (max-width: 1024px) {
  .header-logo-wrap__container-wide {
    height: 100px;
    left: calc(50% - 150px);

    .header-logo-wrap {
      max-width: 300px;
      height: 100px;
    }
  }
}


@media screen and (min-width: 800px) and (max-width: 870px) {
  .header-logo-wrap__container-wide {
    height: 100px;
    left: calc(50% - 110px);

    .header-logo-wrap {
      max-width: 220px;
      height: 100px;
    }
  }
}


@media screen and (min-width: 780px) and (max-width: 800px) {
  .header-logo-wrap__container-wide {
    height: 100px;
    left: calc(50% - 95px);

    .header-logo-wrap {
      max-width: 190px;
      height: 100px;
    }
  }
}

@media screen and (max-width: 750px) {
  .header-dual {
    width: 15px;
    height: 60px;

    &-container {
      top: 30px;
      left: calc(50% - 7.5px);
    }

    &-plus {
      width: 15px;
      height: 15px;
    }

    &-first {
      left: -120px;
      width: 110px;
      height: 60px;

      &-square {
        width: 60px;
        left: -70px;
      }
    }

    &-second {
      right: -120px;
      width: 110px;
      height: 60px;

      &-square {
        width: 60px;
        right: -70px;
      }
    }
  }
}

@media screen and (min-width: 580px) and (max-width: 779px) {
  .header-logo-wrap__container-wide {
    left: calc(50% - 130px);

    .header-logo-wrap {
      max-width: 260px;
      height: 80px;
    }
  }
}

@media screen and (max-width: 579px) {
  .header-logo-wrap {
    max-width: 180px;
    height: 80px;

    &__container {
      left: calc(50% - 90px);
    }
  }

  .header-logo-wrap__container-wide {
    left: calc(50% - 150px);

    .header-logo-wrap {
      max-width: 300px;
      height: 80px;
    }
  }

  .header-dual {
    width: 15px;
    height: 40px;

    &-container {
      top: 30px;
      left: calc(50% - 7.5px);
    }

    &-plus {
      width: 15px;
      height: 15px;
    }

    &-first {
      left: -100px;
      width: 90px;
      height: 40px;
      &-square {
        width: 30px;
        left: -40px;
      }
    }

    &-second {
      right: -100px;
      width: 90px;
      height: 40px;
      &-square {
        width: 30px;
        right: -40px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .header-logo-wrap__container-wide {
    left: calc(50% - 120px);

    .header-logo-wrap {
      max-width: 240px;
      height: 80px;
    }
  }
}

@media screen and (max-width: 340px) {
  .header-logo-wrap__container-wide {
    left: calc(50% - 100px);

    .header-logo-wrap {
      max-width: 200px;
      height: 80px;
    }
  }
}
