app-new-faq-page { width: 100% }

.new-faq {
  display: flex;
  justify-content: center;
  width: 100%;

  &-container { width: 100%; max-width: 1210px; }

  &-search {
    position: relative;
    margin-top: 178px;
    &__close {
      position: absolute;
      right: 15px;
      top: calc(50% - 10px);
      height: 20px;
      width: 20px;
      cursor: pointer;
      img { height: 100%; width: 100%; }
    }
    input {
      height: 73px;
      border: 1px solid #979797;
      border-radius: 8px;
      font-family: var(--root-font-family);
      padding: 0 40px 0 20px;
      font-size: 26px;
      font-weight: 100;
      box-sizing: border-box;
      width: 100%;
      &::placeholder {
        color: rgba(151, 151, 151, 0.4);
      }
    }
  }

  &-accordion { margin-top: 18px; }

  .mat-expansion-panel-content, .mat-expansion-panel-header {
    font-family: var(--root-font-family);
  }

  &-section-header {
    &.mat-expansion-panel-header {
      padding: 29px 12px 27px;
      border-bottom: 1px solid #979797;
    }
    .mat-expansion-panel-header-title {
      color: #757575;
      font-size: 36px;
      font-weight: 300;
      letter-spacing: 0.67px;
      line-height: 55px;
    }
  }

  &-subject {
    height: 20px;
    color: #BBBBBB;
    font-size: 14px;
    letter-spacing: 0.33px;
    line-height: 21px;
    text-align: center;
    margin-top: 113px;
    margin-bottom: 109px;
  }
}

@media screen and (max-width: 960px) {
  .new-faq {
    &-container { padding: 0 15px; box-sizing: border-box; }
    &-search input { font-size: 16px; padding: 0 40px 0 10px; height: 40px; }
    &-section-header.mat-expansion-panel-header { padding: 5px 0; }
    &-section-header .mat-expansion-panel-header-title { font-size: 16px;  }
    .mat-expansion-panel-body { padding: 0 0 16px; }
  }
}
