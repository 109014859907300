.main-content_freeway {
  .text-input input[type="text"],
  .text-input input[type="email"],
  .text-input input[type="number"],
  .text-input textarea,
  .text-input select {
    @include Freeway-Input;
  }

  input.is-invalid-i,
  .text-input input.is-invalid-i,
  .registration-select-input select.is-invalid-i {
    border-bottom: 1px solid #CA0000;
  }
  .text-input input.is-invalid-i_full,
  .text-input select.is-invalid-i_full,
  .text-input textarea.is-invalid-i_full,
  .user-entity-form .text-input textarea.is-invalid-i_full,
  .user-entity-form .text-input select.is-invalid-i_full,
  .registration-select-input select.is-invalid-i {
    border: 1px solid #CA0000;
  }

  //.text-input {
  //  position: relative;
  //  &:before {
  //    content: '';
  //    position: absolute;
  //    right: 30px;
  //    top: 15px;
  //    height: 22px;
  //    width: 21px;
  //    background: url('~/assets/images/freeway/freeway-error-icon.svg');
  //  }
  //}
}
