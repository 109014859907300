app-freeway-calculator { width: 100%; }

.freeway-calculator {
  margin: 82px auto 0;
  width: 100%;
  max-width: 1091px;
  padding: 26px 60px 39px;
  box-sizing: border-box;
  border: 1px solid #979797;
  border-radius: 26px;
  background-color: #E9F3FD;
  position: relative;
  z-index: 2;

  h4 {
    margin: 0 auto;
    width: 100%;
    color: var(--secondary-color);
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.13px;
    line-height: 49px;
    text-align: center;
  }

  &__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 40px 40px;
    box-sizing: border-box;
  }

  &-form {
    width: 100%;
    max-width: 382px;
    padding-top: 17px;

    .mat-mdc-form-field {
      .mat-mdc-form-field-outline {
        background: #fff;
      }
    }

    &__row {
      position: relative;
      min-height: 80px;
      &__input-error {
        border: 1px solid #CA0000 !important;
      }

      &__error {
        color: #CA0000;
        font-size: 16px;
        letter-spacing: 0.38px;
        line-height: 26px;
      }

      &__error-icon {
        position: absolute;
        right: 12px;
        top: 16px;
        height: 22px;
        width: 22px;
      }
    }

    &__radio {
      .mat-mdc-radio-outer-circle {
        border: 1px solid #979797;
        background: #fff;
      }
    }

    .label-text {
      display: block;
      margin-top: 6px;
      margin-bottom: 8px;
      margin-left: 16px;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.26px;
      line-height: 21px;

      &_radio {
        margin-top: 33px;
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.37px;
        line-height: 24px;
      }
    }

    .label-radio {
      color: #000;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: -0.24px;
      line-height: 30px;
      font-family: var(--root-font-family);
    }
  }

  &-output {
    width: 100%;
    max-width: 435px;
    margin-top: 66px;
    &__img {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      max-width: 172px;
      margin-bottom: 30px;

      svg, img { width: 172px; height: auto; }

      p {
        margin-left: 12px;
        color: var(--primary-color);
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.39px;
        line-height: 37px;
      }

      &__quote {
        font-size: 26px;
      }
    }

    &__title {
      color: #000;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.39px;
      line-height: 32px;

      &_margin-top {
        margin-top: 30px;
      }
    }
    &__amount {
      color: var(--secondary-color);
      font-size: 36px;
      font-weight: 700;
      letter-spacing: -0.58px;
      line-height: 49px;
    }
    &__btn.mat-btn {
      margin-top: 67px;
    }

    &__subject {
      margin-top: 26px;
      color: #8F8F8F;
      font-size: 16px;
      letter-spacing: 0.31px;
      line-height: 21px;
    }
  }

  &-terms {
    color: #8F8F8F;
    font-size: 16px;
    letter-spacing: 0.31px;
    line-height: 21px;
  }

}

@media only screen and (max-width: 1099px) {
  .freeway-calculator {
    margin: 0;
    padding: 0 0 52px;
    width: 100%;
    box-sizing: border-box;
    border-left: none;
    border-right: none;
    border-radius: 0;

    h4 { max-width: 335px; font-size: 24px; letter-spacing: -0.09px; line-height: 33px; margin-top: 26px; font-weight: 600; }

    &__container { flex-direction: column; align-items: center; padding: 10px 40px 0px; }

    &-form {
      .label-text {

        &_radio {
          margin-top: 0px;
          margin-bottom: 15px;
        }
      }

      &__radio {
        .mat-mdc-radio-label { display: flex; justify-content: center; }
      }
    }

    &-output {
      margin-top: 42px;
      &__img { width: 100%; max-width: none; justify-content: center;
        svg, img { width: 160px; height: auto; }
      }

      &__title {
        letter-spacing: -0.29px;
        line-height: 24px;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        &_margin-top {
          margin-top: 14px;
        }
      }
      &__amount { text-align: center; }

      &__btn {
        margin-top: 31px;
        &-container { display: flex; justify-content: center; }
      }
    }

    &-terms {
      padding: 20px 40px 0;
    }
    .mat-mdc-radio_12-months { margin-left: 8px; }
  }
}
