app-freeway-confirm { width: 100%; }

.freeway-confirm {
  padding: 25px 0 123px;
  margin-bottom: -133px;
  width: 100%;
  position: relative;
  font-family: var(--root-font-family);
  background: #fff;

  &__back-image {
    content: "";
    background-image: url('~/assets/images/freeway/freeway-personalize-back.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position-y: bottom;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
  }

  &__back {
    position: absolute;
    z-index: 1;
    top: 50px;
    background-repeat: no-repeat;
    background-size: auto 100%;
    opacity: .4;
    &_left {
      left: 0;
      height: 420px;
      width: 600px;
      background-position-x: 90%;
      background-position-y: bottom;
      background-image: url('../../../assets/images/residential-left.svg');
    }
    &_right {
      right: 0;
      height: 420px;
      width: 600px;
      background-position-x: 0;
      background-position-y: bottom;
      background-image: url('../../../assets/images/residential-right.svg');
    }
  }

  &__content {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 150px;
    width: 250px;
    text-align: center;
    &__title {
      color: #000;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.33px;
      line-height: 19px;
    }
    &__text {
      margin-top: 6px;
      color: #000;
      font-size: 12px;
      letter-spacing: 0.14px;
      line-height: 17px;
    }
  }

  &-dialog {
    position: relative;
    z-index: 2;
    margin: 42px auto 0;
    padding: 34px 53px 42px;
    box-sizing: border-box;
    width: 100%;
    max-width: 670px;
    background-color: #F1F2F6;
    box-shadow: 0 19px 19px -1px rgba(0,0,0,0.5);

    &__title {
      color: var(--secondary-color);
      font-size: 36px;
      font-weight: 700;
      letter-spacing: -0.13px;
      line-height: 38px;

      &_highlight {
        color: var(--primary-color);
      }
    }

    &__content {
      margin-top: 32px;
      color: #040404;
      font-size: 16px;
      letter-spacing: 0.38px;
      line-height: 25px;

      li {
        &::before {
          display: inline-block;
          margin-right: 10px;
          content: '\2022'
        }
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 57px;
    }

    &__btn_outline.mat-btn.outline, &__btn_outline.mat-btn.outline.mat-mdc-button {
      margin-top: 7px;
      padding: 8px 11px;
      line-height: 21px;
      border: none;
    }
  }
}

@media only screen and (max-width: 1099px) {
  .freeway-confirm {
    padding: 25px 0 0;
    &__back, &__content { display: none; }
    &-dialog {
      margin: 0 auto 30px;
      padding: 0 15px 68px;

      &__title {
        padding: 38px 35px 22px;
        width: auto;
        color: #0047BB;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -0.09px;
        line-height: 30px;
      }

      &__content {
        margin-top: 16px;
        max-width: 364px;
        color: #040404;
        font-size: 16px;
        letter-spacing: 0.33px;
        line-height: 25px;

        li {
          position: relative;
          padding-left: 30px;
          &::before {
            position: absolute;
            display: block;
            top: 0;
            left: 5px;
          }
        }
      }
    }
  }
}

