@mixin Material-Ordinary-Button {
  padding: 8px 32px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.38px;
  line-height: 30px;
  border-radius: 8px;
  font-family: var(--root-font-family);
  color: #fff;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  text-align: center;

  .mdc-button__label {
    color: #fff;
  }

  &.mat-mdc-button {
    padding: 22px 24px;
  }

  &.secondary {
    border: 1px solid var(--secondary-color);
    background-color: var(--secondary-color);
  }

  &.warning {
    border: 1px solid var(--warning-color);
    background-color: var(--warning-color);
  }

  &.outline {
    border: 1px solid var(--primary-color);
    background-color: rgba(0, 0, 0, 0);
    color: var(--primary-color);
    .mdc-button__label {
      color: var(--primary-color);
    }
    &.secondary {
      border: 1px solid var(--secondary-color);

      .mdc-button__label {
        color: var(--secondary-color);
      }
    }
    &.warning {
      border: 1px solid var(--warning-color);

      .mdc-button__label {
        color: var(--warning-color);
      }
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    border-color: rgba(0, 0, 0, .12);
    background-color: rgba(0, 0, 0, .12);
  }

  &.full-width {
    width: 100%;
  }
}

.mat-btn {
  @include Material-Ordinary-Button;

  &.mat-mdc-button {
    @include Material-Ordinary-Button;
  }
}
