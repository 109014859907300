.residential-accordion {
  mat-expansion-panel-header {
    min-height: 44px;
    height: auto!important;
  }
  .mat-expansion-panel {
    margin-top: 10px;
  }
  .mat-expansion-indicator {
    margin-right: 10px;
  }
  .mat-expansion-panel-header-title { font-family: var(--root-font-family); }

  &__title {
    color: var(--secondary-color);
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0 2px;
  }
  &__description {
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 10px;
  }

  .mat-expansion-panel-body {
    padding: 6px 24px 16px;
  }
}

.residential-insured-details {
  &__section-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      padding-right: 0;
      margin-left: auto;
    }
  }

  &__section-title {
    color: var(--primary-color);
    font-size: 16px;
    font-weight: bold;
  }

  &__section-step {
    margin-left: 10px;
    padding-top: 2px;
    font-size: 14px;
    font-weight: 600;
    color: #ced6e0;
    text-align: right;
  }
}

@media screen and (max-width: 579px) {
  .residential-accordion {
    .mat-expansion-panel-body {
      padding: 6px 12px 16px;
    }
  }
}
