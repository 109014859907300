:root {
  --primary-color: #00AEE4;
  --secondary-color: #2E308F;
  --warning-color: #FF6242;
}

.cc-btn.cc-allow:hover {
  color: white;
}

@import "./assets/styles/main/index";

app-main { width: 100%; }

.main {
  width: 100%;

  &-image-svg {
    fill: var(--primary-color);
  }

  &-coverages {
    background: var(--secondary-color);
    font-weight: 100;
    position: relative;

    &__title {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 13px;
      letter-spacing: 0.31px;
      line-height: 44px;
      height: 44px;


      &_freeway {
        font-family: var(--root-font-family);
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 0.53px;
        padding: 5px 0;
      }
    }

    &-container {
      display: flex;
      justify-content: center;
      min-height: 157px;
    }

    &__item {
      padding: 0 27px;
      width: 201px;
      box-sizing: border-box;

      &:hover, &_active { background: var(--primary-color); cursor: pointer; }

      &__icon {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        width: 100%;
        height: 80px;

        img { height: 80px; }
        &_renters {
          img { height: 60px; margin-top: 10px; }
        }
        &_business {
          img { height: 50px; margin-top: 20px; }
        }
        &_freeway-rent-guarantee { img { height: 41px; margin-top: 25px; } }
        &_freeway-residential { img { height: 50px; margin-top: 15px; } }
        &_freeway-auto-insurance { img { height: 30px; margin-top: 35px; } }
      }

      p {
        color: #fff;
        font-size: 12px;
        letter-spacing: 0.29px;
        line-height: 18px;
        font-weight: 500;
        text-align: center;
      }

      &_freeway {
        padding: 0;
        width: 174px;
        p {
          font-family: var(--root-font-family);
          font-size: 15px;
          font-weight: 700;
          letter-spacing: 0.36px;
          line-height: 20px;
        }
      }
    }
  }

  &-personalize {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: #fff;
    padding: 79px 0 69px;
    overflow: hidden;

    &__back {
      position: absolute;
      z-index: 1;
      height: 100%;

      svg { width: 100%; height: auto; }

      &_left {
        top: 5%;
        right: calc(50% + 340px);
        width: 650px;
      }

      &_right {
        top: 5%;
        left: calc(50% + 300px);
        width: 700px;
      }
    }

    h1 {
      width: 100%;
      max-width: 599px;
      color: var(--secondary-color);
      font-size: 34px;
      font-weight: 600;
      letter-spacing: 0.81px;
      line-height: 41px;
      text-align: center;
      position: relative;
      z-index: 2;
    }

    &_highlight {
      color: var(--primary-color);
    }

    p {
      margin-top: 27px;
      width: 100%;
      max-width: 676px;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.38px;
      line-height: 27px;
      text-align: center;
      position: relative;
      z-index: 2;
    }

    b {
      font-size: 18px;
      margin-top: 27px;
      font-weight: 600;
    }

    &__btn.mat-btn {
      margin-top: 56px;
      padding: 15px 0;
      width: 100%;
      max-width: 330px;
      border-radius: 10px;
      line-height: 25px;
      text-align: center;
      position: relative;
      z-index: 2;
    }
  }

  &-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px 0 35px;
    background: #F1F2F6;

    h2 {
      width: 100%;
      max-width: 486px;
      color: var(--secondary-color);
      font-size: 39px;
      font-weight: 600;
      letter-spacing: -0.14px;
      line-height: 59px;
      text-align: center;
    }

    &_highlight {
      color: var(--primary-color);
    }

    p {
      width: 100%;
      max-width: 508px;
      margin-top: 21px;
      color: #000;
      font-size: 16px;
      letter-spacing: 0.25px;
      line-height: 25px;
      text-align: center;
    }

    &__container {
      margin-top: 43px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .main-step {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 37px;
      padding: 48px 24px 22px;
      box-sizing: border-box;
      //max-width: 363px;
      max-width: 413px;
      border-radius: 9px;
      background: var(--secondary-color);
      color: #fff;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.38px;
      line-height: 25px;

      &__number {
        position: absolute;
        top: -7px;
        left: calc(50% - 106px);
        padding: 3px 37px 3px 67px;
        border-radius: 70px 0 70px 0px;
        color: #fff;
        font-size: 28px;
        letter-spacing: 0.84px;
        line-height: 42px;
        text-align: center;
        background: var(--primary-color);
      }
    }

    h3 {
      margin-top: 43px;
      width: 100%;
      color: var(--secondary-color);
      font-size: 32px;
      font-weight: 600;
      letter-spacing: -0.49px;
      line-height: 48px;
      text-align: center;
    }

  }

  &-background-container {
    position: relative;
    width: 100%;
    height: 1px;
    overflow: visible;
    z-index: 1;

    &__back {
      position: absolute;
      top: 54px;
      left: -100px;
      height: 1192px;
      width: 1192px;
      opacity: 0.29;
      background-color: #E7F5FC;
      border-radius: 50%;
      z-index: 1;
    }
  }

  &-terms {
    margin: 11px auto 0;
    width: 100%;
    max-width: 1098px;
    color: #8F8F8F;
    font-size: 13px;
    font-weight: 100;
    letter-spacing: 0.25px;
    line-height: 20px;
    text-align: center;
    position: relative;
    z-index: 2;
  }

  &__more-info {
    margin: 79px auto 0;
    width: 100%;
    color: var(--secondary-color);
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -0.49px;
    line-height: 48px;
    text-align: center;
    position: relative;
    z-index: 2;

    &_highlight {
      color: var(--primary-color);
    }
  }

  &-summaries {
    display: flex;
    justify-content: space-around;
    margin: 44px auto 0;
    width: 100%;
    max-width: 1252px;
    position: relative;
    z-index: 2;

    a {
      display: flex;
      width: auto;
      max-width: 303px;
    }

    &__btn {
      padding: 18px 20px 20px;
      margin: 0 10px;
      width: 100%;
      max-width: 303px;
      font-size: 16px;
      min-width: 200px;
      font-weight: 600;
      letter-spacing: 0.38px;
      line-height: 25px;
      border-radius: 9px;
      color: #fff;
      text-align: center;
      background: var(--secondary-color);
      border: 1px solid var(--secondary-color);
    }
  }

  hr {
    margin: 61px auto 0;
    height: 1px;
    width: 100%;
    max-width: 1252px;
    background: #979797;
  }
}

@media only screen and (max-width: 1099px) {
 .main {

   //&-coverages {
   //  flex-direction: column; align-items: center;
   //  &__item { width: 100%; box-sizing: border-box; padding: 0 27px 30px; }
   //}

   &-coverages {
     min-height: 168px;

     &-container {
       flex-wrap: wrap;
     }

     &_freeway {
       //padding: 0 37px 33px;
     }

     &__title {
       margin-bottom: 10px;
       &_freeway {
         font-weight: 500;
       }
     }

     &__item {
       width: 50%;
       box-sizing: border-box;
       padding: 0 0 25px;
       font-size: 10px;
       letter-spacing: 0.24px;
       line-height: 14px;

       p {
         font-size: 16px;
         font-weight: bold;
         letter-spacing: 0.38px;
         line-height: 22px;
       }

       &__icon {
         height: 60px;
         margin-top: 40px;
         img { height: 54px; }
         &_renters { img { height: 42px; margin-top: 7px; } }
         &_business { img { height: 40px; margin-top: 10px; } }

         &_freeway-rent-guarantee { img { height: 42px; margin-top: 7px; } }
         &_freeway-residential { img { height: 40px; margin-top: 10px; } }
         &_freeway-auto-insurance { img { height: 30px; margin-top: 15px; } }
       }
     }
   }

   &-personalize {
     padding: 44px 15px;
     &__back { display: none }
     h1 { font-size: 24px; letter-spacing: 0.55px; line-height: 31px; max-width: 354px; }

     p { font-size: 15px; line-height: 20px; letter-spacing: 0.16px; max-width: 283px;  }
     b { font-size: 15px; line-height: 20px; letter-spacing: 0.16px; }

     &__btn { margin-top: 36px;}
   }

   &-steps {
     padding-bottom: 30px;
     &__container { margin-top: 23px; flex-direction: column; align-items: center; }

     h2 { max-width: 299px; font-size: 24px; letter-spacing: -0.09px; line-height: 33px; }
     p { max-width: 294px; font-size: 15px; letter-spacing: 0.16px; line-height: 20px; }
     h3 {  max-width: 345px; font-size: 24px; letter-spacing: -0.37px; line-height: 33px; margin-top: 30px; }

     .main-step {
       margin: 40px 26px 0;
       width: 100%;
       &__number {
         //top: -7px;
         //left: -22px;
         left: calc(50% - 76px);
         padding: 3px 27px;
       }
     }
   }

   &-background-container { display: none; }

   &-terms { max-width: 383px; margin-top: 26px; box-sizing: border-box; padding: 0 15px; text-align: left; }

   &__more-info { margin-top: 39px; max-width: 345px; font-size: 24px; letter-spacing: -0.37px; line-height: 33px; }

   &-summaries {
     margin-top: 2px; padding: 0 15px; box-sizing: border-box; flex-direction: column; align-items: center;
     a { width: 100%; }
     &__btn { margin-top: 20px; }
   }

   hr { display: none; }
 }

  .main-coverages-three-items .main-coverages__item {
    width: 33%;
  }
}

@media only screen and (max-width: 599px) {
  .main {
    &-coverages {
      min-height: auto;

      &-container { min-height: auto; }
      &__title { display: none; }
      &__item {
        padding: 0 0 10px;

        p { font-size: 10px; line-height: 15px; font-weight: 500; }

        &__icon {
          height: 40px;
          margin-top: 10px;
          img { height: 37px; }
          &_renters { img { height: 30px; margin-top: 7px; } }
          &_business { img { height: 30px; margin-top: 10px; } }

          &_freeway-rent-guarantee { img { height: 28px; margin-top: 7px; } }
          &_freeway-residential { img { height: 30px; margin-top: 7px; } }
          &_freeway-auto-insurance { img { height: 20px; margin-top: 15px; } }
        }
      }
    }
  }
}

@media only screen and (min-width: 450px) {
  .ngx-toastr {
    width: 400px !important;
  }
}

.maintenance-page {
  background: #F2F4FA;
}
