.main-content_freeway {

  .mat-mdc-form-field {
    padding-top: 23px;
  }
  .mat-mdc-form-field-wrapper {
    padding-right: 35px;
  }

  .guarantee2_header {
    margin-bottom: 25px;
  }
  .mat-mdc-form-field-appearance-outline:not(.mat-form-field-disabled):not(.mat-form-field-invalid) .mat-mdc-form-field-flex .mat-mdc-form-field-outline {
    border: 1px solid var(--border-color);
    border-radius: 8px;
    .mat-mdc-form-field-outline-start,
    .mat-mdc-form-field-outline-gap,
    .mat-mdc-form-field-outline-end {
      border: none;
    }
  }
  .mat-mdc-form-field-label-wrapper {
    top: -60px;
  }
  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: 0.5em 0 1em 0;
  }
  .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label,
  .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float .mat-mdc-input-server:focus+.mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
    transform: none;
    width: 100%;
  }
  .mat-mdc-form-field-label {
    font-family: var(--root-font-family);
    font-size: 15px;
    line-height: 22px;
    color: #414648!important;
  }
  .mat-mdc-form-field-outline-gap { display: none; }
  .mat-mdc-form-field-appearance-outline .mat-mdc-select-arrow-wrapper { transform: none; }
  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-prefix, .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-suffix { top: 0.4em }

  /* invalid input -> show error-icon and change border */
  .mat-mdc-input-invalid .mat-mdc-input-placeholder {
    color: #CA0000;
  }
  .mat-mdc-input-invalid .mat-mdc-input-ripple {
    background-color: #CA0000;
  }

  //.mat-form-field-invalid {
  //
  //  .mat-form-field-flex {
  //    position: relative;
  //
  //    &:before {
  //      content: '';
  //      position: absolute;
  //      right: 12px;
  //      top: 19px;
  //      height: 22px;
  //      width: 21px;
  //      background: url('~/assets/images/freeway/freeway-error-icon.svg');
  //    }
  //  }
  //
  //  &.mat-form-field-type-mat-select {
  //    .mat-form-field-flex {
  //      &:before {
  //        right: 35px;
  //      }
  //    }
  //  }
  //  &.date-with-suffix {
  //    .mat-form-field-flex {
  //      &:before {
  //        right: 47px;
  //      }
  //    }
  //  }
  //}

  //.mat-form-field-suffix {
  //  position: relative;
  //}

  .form-descriptor {
    position: absolute;
    // right: -40px;
    // top: -25px;
    color: var(--descriptor-color);
  }

  .coverage-checkbox {
    position: relative;
    .form-descriptor {
      right: 5px;
      top: 7px;
      font-size: 150%;
      line-height: 1.125;
    }
  }

  .date-with-suffix {
    .form-descriptor {
      top: 10px;
    }
  }

  .registration-checkbox {
    .form-descriptor {
      right: 3px;
      top: 3px;
    }
  }
}
