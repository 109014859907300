@font-face {
  font-family: HelveticaNeue;
  src:
    url("/assets/fonts/bozzuto/HelveticaNeueLTStd55Roman.woff2") format("woff2"),
    url("/assets/fonts/bozzuto/HelveticaNeueLTStd55Roman.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: HelveticaNeue;
  src:
    url("/assets/fonts/bozzuto/HelveticaNeueLTStd75Bold.woff2") format("woff2"),
    url("/assets/fonts/bozzuto/HelveticaNeueLTStd75Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}



body.website-bozzuto {
  font-family: HelveticaNeue, serif !important;

  background: linear-gradient(rgba(0, 0, 0, 0.43), rgba(0, 0, 0, 0.43)), url('/assets/images/bozzuto/bozzuto-bg-2560.webp') no-repeat center center fixed;
  background-size: 100%;

  // Large Desktop (Retina)
  @media (min-width: 2560px) {
    background:
      linear-gradient(rgba(0, 0, 0, 0.43), rgba(0, 0, 0, 0.43)), /* Overlay */
      url('/assets/images/bozzuto/bozzuto-bg-1920.webp') no-repeat center center fixed; /* Background Image */
  }

  // Desktop (Standard)
  @media (max-width: 2560px) and (min-width: 1920px) {
    background:
      linear-gradient(rgba(0, 0, 0, 0.43), rgba(0, 0, 0, 0.43)), /* Overlay */
      url('/assets/images/bozzuto/bozzuto-bg-1920.webp') no-repeat center center fixed; /* Background Image */

    background-size: cover;
  }

  // Tablet
  @media (max-width: 1920px) and (min-width: 768px) {
    background:
      linear-gradient(rgba(0, 0, 0, 0.43), rgba(0, 0, 0, 0.43)), /* Overlay */
      url('/assets/images/bozzuto/bozzuto-bg-1536.webp') no-repeat center center fixed; /* Background Image */

    background-size: cover;
  }

  // Mobile (Portrait)
  @media (max-width: 768px) and (min-width: 480px) {
    background:
      linear-gradient(rgba(0, 0, 0, 0.43), rgba(0, 0, 0, 0.43)), /* Overlay */
      url('/assets/images/bozzuto/bozzuto-bg-1080.webp') no-repeat center center fixed; /* Background Image */

    background-size: cover;
  }

  // Small Mobile
  @media (max-width: 480px) {
    background:
      linear-gradient(rgba(0, 0, 0, 0.43), rgba(0, 0, 0, 0.43)), /* Overlay */
      url('/assets/images/bozzuto/bozzuto-bg-720.webp') no-repeat center center fixed; /* Background Image */

    background-size: cover;
  }

  .header-main-nav a {
    color: white !important;
  }

  .footer-container__copyright,
  .footer-container__content__terms a,
  .footer-navigation__link a {
    color: white !important;
  }

  .login-register {
    ul li a {
      color: white !important;
    }

    ul li:last-child {
      border-left: 3px solid #fff !important;
    }
  }

  .tenant-boarding__header {
    color: #fff;
  }

  .tenant-boarding__cloud-container, .residential-form-main,
  app-login, app-register, app-forgot-password, app-change-password, app-claims-from-page, app-accept-invitation,
  app-faq-page, app-giveback-page, app-privacy-policy-page, app-terms-of-use-page, app-about-us-page, app-contact-us-page, app-pci-insurance-fraud-provisions, app-insurance-producer-licensure {
    border-radius: 0;
  }

  .new-faq-search input {
    border-radius: 0;
  }

  .new-faq-container {
    .mat-accordion .mat-expansion-panel:first-of-type,
    .mat-accordion .mat-expansion-panel:last-of-type {
      border-radius: 0;
    }
  }

  .main-image-svg {
    fill: #000 !important;
  }

  .header-drop-nav-icon {
    background: url('/assets/images/menu_new_white.svg') !important;
  }

  .profile-background {
    background: none !important;
  }

  .footer-container-mobile__copyright {
    color: #fff;
  }

  .residential-large-text, .registration-accent,
  .residential-large-text,
  .residential-content-description,
  .qbe-disclosure {
    color: #fff;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .registration-accent img {
    display: none;
  }
}

