.user-entities {

 .add-entity {
    margin-bottom: 35px;
    margin-left: 22px;
  }

  &__quote-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-content {
    display: block;
    box-sizing: border-box;
    padding: 47px 15px 40px;
    border-radius: 5px;
    border: 0;
    background-color: #fff;
    box-shadow: 0 2px 3px -1px #dcdde2;
    text-transform: capitalize;

    .table-container {
      min-height: 200px;
      position: relative;
      width: 100%;
    }

    .table-container .mat-mdc-table {
      font-family: var(--root-font-family);
    }

    .table-container table {
      width: 100%;
    }

    .loading-shade {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 56px;
      right: 0;
      background: rgba(0, 0, 0, 0.10);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .table-container .mat-mdc-header-cell {
      font-size: 15px;
      font-weight: 600;
      color: #2f3542;
    }

    .table-container .table-row {
      cursor: pointer;
    }

    .table-container .table-row:hover {
      background: #f1f2f6;
    }
  }
}

@media screen and (max-width: 960px) {
  .user-entities {

    .add-entity { margin-left: 0; }

    &-content {
      padding: 17px 15px 20px;

      .table-container {
        overflow-x: scroll;
      }

      .table-container table {
        width: 660px;
      }
    }
  }
}
