.profile-menu {
  display: block;
  margin-left: 30px;
  box-sizing: border-box;
  padding: 30px;
  width: 365px;
  border-radius: 5px;
  border: 0;
  background-color: #fff;
  text-transform: capitalize;

  & ul {
    display: flex;
    flex-direction: column;

    & li {
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }

      & a {
        font-weight: 400;
        color: #2f3542;
      }
    }

    .selected-profile-link {
      a {
        color: #1b75ba;
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .profile-menu { width: 100%; }
}

@media screen and (max-width: 900px) {
  .profile-menu { margin-left: 10px; }
}

@media screen and (max-width: 599px) {
  .profile-menu { padding: 20px 15px; margin-left: 0px; font-size: 16px; }
}
