:root {
  --primary-color: #167EAD;
  --secondary-color: #004EBB;
  --warning-color: #C74E10;
  --descriptor-color: #dd7c10;
  --border-color: #157EAD;
}

.freeway {
  font-family: var(--root-font-family);
  width: 100%;

  &-image-svg {
    fill: var(--primary-color);
  }

  &-personalize {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 59px 0 67px;
    overflow: hidden;
    //background-image: url('~/assets/images/freeway/freeway-personalize-back.svg');
    //background-repeat: no-repeat;
    //background-size: 100% auto;
    //background-position-y: bottom;
    background: #E9F3FD;

    &__back {
      position: absolute;
      z-index: 1;
      height: 100%;

      svg { width: 100%; height: auto; }

      &_left {
        top: 5%;
        right: calc(50% + 340px);
        width: 650px;
      }

      &_right {
        top: 5%;
        left: calc(50% + 300px);
        width: 700px;
      }
    }

    h1 {
      width: 100%;
      max-width: 774px;
      color: var(--secondary-color);
      font-size: 36px;
      font-weight: 700;
      letter-spacing: -0.84px;
      line-height: 41px;
      text-align: center;
      position: relative;
      z-index: 2;
    }

    &_highlight {
      color: var(--primary-color);
    }

    p {
      margin-top: 31px;
      width: 100%;
      max-width: 774px;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.41px;
      line-height: 21px;
      text-align: center;
      position: relative;
      z-index: 2;
    }

    b {
      font-size: 18px;
      margin-top: 27px;
      font-weight: 600;
    }

    &__btn.mat-btn {
      margin-top: 34px;
    }
  }

  &-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    //padding: 94px 0 0;
    padding: 24px 0 0;
    background: #fff;

    h2 {
      width: 100%;
      max-width: 667px;
      color: var(--secondary-color);
      font-size: 36px;
      font-weight: 700;
      letter-spacing: -0.13px;
      line-height: 36px;
      text-align: center;
    }

    &_highlight {
      color: var(--primary-color);
    }

    p {
      width: 100%;
      max-width: 605px;
      margin-top: 12px;
      color: #000;
      font-family: var(--root-font-family);
      font-size: 16px;
      letter-spacing: 0.26px;
      line-height: 21px;
      text-align: center;
    }

    &__container {
      margin-top: 82px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .freeway-step {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 37px;
      padding: 40px 32px 27px 40px;
      box-sizing: border-box;
      max-width: 363px;
      border-radius: 9px;
      background: var(--secondary-color);
      color: #fff;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.38px;
      line-height: 21px;

      &__number {
        position: absolute;
        top: -20px;
        left: calc(50% - 92.5px);
        padding: 9px 0;
        width: 185px;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.72px;
        line-height: 32px;
        text-align: center;
        background: var(--primary-color);
      }
    }

    h3 {
      //margin-top: 41px;
      margin-top: 82px;
      width: 100%;
      color: var(--secondary-color);
      font-size: 36px;
      font-weight: 700;
      letter-spacing: -0.55px;
      line-height: 49px;
      text-align: center;
    }

  }

  &-background-container {
    position: relative;
    width: 100%;
    height: 1px;
    overflow: visible;
    z-index: 1;

    &__back {
      position: absolute;
      top: 54px;
      left: -100px;
      height: 1192px;
      width: 1192px;
      opacity: 0.29;
      background-color: #E7F5FC;
      border-radius: 50%;
      z-index: 1;
    }
  }

  &-terms {
    margin: 44px auto 0;
    width: 100%;
    max-width: 1098px;
    color: #8F8F8F;
    font-size: 13px;
    font-weight: 100;
    letter-spacing: 0.25px;
    line-height: 20px;
    text-align: center;
    position: relative;
    z-index: 2;
  }

  &__more-info {
    margin: 122px auto 0;
    width: 100%;
    color: var(--secondary-color);
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.55px;
    line-height: 49px;
    text-align: center;
    position: relative;
    z-index: 2;

    &_highlight {
      color: var(--primary-color);
    }
  }

  &-summaries {
    display: flex;
    justify-content: space-between;
    margin: 33px auto 0;
    width: 100%;
    max-width: 1415px;
    position: relative;
    z-index: 2;

    a {
      display: flex;
      width: auto;
      max-width: 390px;
    }

    &__btn {
      padding: 15px 20px;
      margin: 0 10px;
      width: 100%;
      font-size: 20px;
      min-width: 200px;
      max-width: 303px;
      font-weight: 700;
      letter-spacing: 0.48px;
      line-height: 27px;
      border-radius: 25px;
      color: #fff;
      text-align: center;
      background: var(--secondary-color);
      border: 1px solid var(--secondary-color);
      font-family: var(--root-font-family);

      &:focus {
        outline-color: #F2994A;
      }
    }
  }

  hr {
    margin: 61px auto 0;
    height: 1px;
    width: 100%;
    max-width: 1252px;
    background: #979797;
  }
}

@media only screen and (max-width: 1099px) {
 .freeway {

   &-personalize {
     padding: 44px 15px;
     background: #E9F3FD;
     &__back { display: none }
     h1 { font-size: 24px; letter-spacing: -0.56px; line-height: 29px; max-width: 331px; font-weight: 600; }

     p { font-size: 16px; line-height: 22px; letter-spacing: 0.41px; max-width: 348px; }
     b { font-size: 15px; line-height: 20px; letter-spacing: 0.16px; }

     &__btn { margin-top: 22px; line-height: 33px; }
   }

   &-steps {
     padding: 15px 0 37px;
     &__container { margin-top: 23px; flex-direction: column; align-items: center; }

     h2 { max-width: 400px; font-size: 24px; font-weight: 600; letter-spacing: -0.09px; line-height: 32px; }
     p { max-width: 378px; font-size: 16px; letter-spacing: 0.26px; line-height: 22px; }
     h3 {  max-width: 358px; font-size: 24px; letter-spacing: -0.37px; line-height: 33px; margin-top: 34px; font-weight: 600; }

     .freeway-step {
       margin: 45px 26px 0;
       padding: 47px 45px;
       width: 100%;
       max-width: 363px;
       text-align: center;
       &:last-child { padding: 47px 35px; }

       &__number {
         padding: 10px 0;
         border-radius: 30px;

         font-size: 20px;
         letter-spacing: 0.6px;
         line-height: 27px;
       }
     }
   }

   &-background-container { display: none; }

   &-terms { max-width: 383px; margin-top: 26px; box-sizing: border-box; padding: 0 15px; text-align: left; }

   &__more-info { margin-top: 39px; max-width: 345px; font-size: 24px; letter-spacing: -0.37px; line-height: 30px; }

   &-summaries {
     margin-top: 2px; padding: 0 15px; box-sizing: border-box; flex-direction: column; align-items: center;
     a { width: 100%; max-width: 303px; }
     &__btn {
       padding: 20px 16px;
       border-radius: 9px;
       margin-top: 20px;
       font-size: 16px;
       font-weight: bold;
       letter-spacing: 0.38px;
       line-height: 22px;
     }
   }

   hr { display: none; }
 }
}
