.file-dnd { // TODO ask Andrey if this shouldn't only be in upload component
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 25px;
  border: 1px dashed rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  transition: 0.2s;

  &.is-active {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &__choose {
    flex-shrink: 0;
    background-color: #51337D;
    border: 1px solid #51337D;
    border-radius: 3px;
    padding: 12px 20px;
    margin-right: 20px;
    font-size: 16px;
    color: #fff;
  }

  &__msg {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #535353;
  }

  &__input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;

    &:focus {
      outline: none;
    }
  }

  &__filename {
    white-space: nowrap;
    overflow: hidden;
    max-width: 600px;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 1000px) {
  .file-dnd {
    &__filename {
      max-width: 400px;
    }
  }
}


@media screen and (max-width: 579px) {
  .file-dnd {
    flex-direction: column;

    &__choose {
      flex-shrink: unset;
      width: 100%;
      margin: 0;
      box-sizing: border-box;
      text-align: center;
    }

    &__msg {
      margin-top: 15px;
      white-space: normal;
    }

    &__filename {
      max-width: 210px;
    }
  }
}

