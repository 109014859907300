.new-faq {
  &-question {
    .mat-expansion-panel-content {
      font-size: 20px;
      letter-spacing: 0.48px;
      line-height: 30px;
      color: #000;
      font-weight: 100;
    }
    &-header {
      &.mat-expansion-panel-header {
        margin-top: 31px;
        min-height: 31px;
        height: auto!important;
      }
      .mat-expansion-panel-header-title {
        color: #00AEE4;
        letter-spacing: 0.48px;
        font-family: var(--root-font-family);
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
  &-content a {
    color: #FF6242;
    text-decoration: underline;
    &:visited { color: #FF6242; }
  }
}


@media screen and (max-width: 960px) {
  .new-faq-question {
    .mat-expansion-panel-content { font-size: 14px; }

    &-header {

      &.mat-expansion-panel-header { padding: 0; }

      .mat-expansion-panel-header {
        &-title { font-size: 14px; }
      }
    }
  }
}
