.freeway-footer {
  width: 100%;
  padding: 31px 36px 37px;
  font-family: var(--root-font-family);
  box-sizing: border-box;
  margin-top: 108px;
  background: var(--secondary-color);
  color: #fff;

  &-logo {
    display: flex;
    align-items: center;
    justify-content: start;
    max-width: 600px;
    width: 100%;

    &-img {
      max-height: 120px;
      max-width: 225px;
    }

    &-plus {
      width: 25px;
      height: 25px;
      margin: 0 20px;
    }
  }

  &-wrap {
    max-width: 1545px;
    width: 100%;
    margin: 0 auto;
  }

  &-row {
    margin-top: 17px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__navigation {
    display: flex;
    justify-content: flex-end;
    width: 500px;

    &__link {
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0.4px;
      line-height: 20px;
      margin-left: 20px;

      a, a:visited { color: #fff; }
    }
  }

  &__copyright {
    width: 100%;
    max-width: 750px;
    font-size: 16px;
    letter-spacing: 0.38px;
    line-height: 21px;

    a, a:visited { color: #fff; text-decoration: underline; }
  }

  &__links {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__router {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 15px;
      letter-spacing: 0.43px;
      line-height: 20px;
      text-align: right;

      a, a:visited { color: #fff; }

      &__slash { margin: 0 5px; }
    }
  }

  &__social-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media only screen and (max-width: 1200px) {
  .freeway-footer {
    position: relative;
    padding: 31px 0;
    margin-top: 35px;

    &-row {
      flex-direction: column-reverse;
    }

    &-logo {
      justify-content: center;
      max-width: 100%;
      margin-top: 15px;

      &-img {
        max-height: 70px;
        max-width: 120px;
      }

      &-plus {
        height: 10px;
        width: 10px;
        margin: 0 10px;
      }
    }

    &__navigation { width: 100%; margin-top: 38px; justify-content: center;
      &__link { margin: 0 8px; }
    }

    &__copyright {
      width: 100%;
      max-width: 337px;
      color: #FFFFFF;
      font-size: 16px;
      letter-spacing: 0.38px;
      line-height: 22px;
      text-align: center;
      margin: 23px auto 0;
      font-weight: 100;
      p { width: 100%; text-align: center; }
    }

    &__links {
      width: 100%;
      margin-top: 24px;
      flex-direction: column;
      &__router {
        flex-direction: column;
        width: 100%;
        font-size: 16px;
        letter-spacing: 0.38px;
        line-height: 22px;
        font-weight: 100;

        &__slash { display: none; }

        a { margin-bottom: 10px; }
      }
    }

    &__social-links {
      position: absolute;
      top: 25px;
      left: 0;
      width: 100%;
      justify-content: center;

      .freeway-footer-layout-social { height: 35px; margin: 0 15px; }
    }
  }
}
