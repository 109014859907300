.user-entity-form {
  display: flex;
  flex-direction: column;
  min-width: 66%;
  box-sizing: border-box;
  padding: 47px 37px 37px 40px;
  border-radius: 5px;
  border: 0;
  background-color: #fff;

  .datapicker-input_mat {
    position: absolute;
    top: 5px;
    right: 3px;
  }

  .text-input {
    margin-top: 12px;

    &-spinner {
      &.spinner:before { left: 70%; }
    }

    select {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      border: 1px solid #ced6e0;
      color: #2f3542;
      font-weight: bold;
      font-size: 14px;
      padding: 0 20px;
      box-sizing: border-box;
    }

    textarea {
      width: 100%;
      height: 150px;
      border-radius: 5px;
      border: 1px solid #ced6e0;
      color: #2f3542;
      font-weight: bold;
      font-size: 14px;
      padding: 20px;
      box-sizing: border-box;
    }

    label {
      width: 100%;
      max-width: 250px;
    }

    &-file input {
      border: none;
      padding: 0;
      height: auto;
    }

    &-autocomplete {
      width: 100%;
    }
  }

  .invalid-input-dedactible {
    position: absolute;
    right: 0;
    bottom: -20px;
  }

  .document {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 210px;
    box-sizing: border-box;
    color: #b5b7ba;
    margin-top: 10px;

    &-remove {
      cursor: pointer;
      margin-left: 20px;
      color: #1b75ba;
      text-decoration: underline;
      font-weight: bold;
      font-size: 17px;
    }
  }

  hr { margin: 20px 0; }
}

@media screen and (max-width: 960px) {
  .user-entity-form {
    padding: 15px;
    .text-input {
      display: block;
      label { display: block; margin-bottom: 10px; }
    }

    hr { margin: 10px 0; }

    .datapicker-input_mat {
      top: 42px;
    }
  }
}
