app-main-calculator { width: 100%; }

.main-calculator {
  margin: 44px auto 0;
  width: 100%;
  max-width: 1038px;
  padding: 19.5px 79px 34px;
  box-sizing: border-box;
  border: 1px solid #979797;
  background-color: #fff;
  position: relative;
  z-index: 2;

  h4 {
    margin: 0 auto;
    width: 100%;
    color: var(--secondary-color);
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -0.11px;
    line-height: 46px;
    text-align: center;
  }

  &__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 12.5px;
  }

  &-form {
    width: 100%;
    max-width: 362px;
    padding-top: 17px;

    &__row {
      min-height: 80px;
      &__input-error {
        border: 1px solid #ff6244 !important;
      }

      &__error {
        color: #ff0000;
        font-size: 12px;
      }
    }

    .label-text {
      display: block;
      margin-top: 6px;
      margin-bottom: 6px;
      color: #000;
      font-size: 19px;
      font-weight: 500;
      letter-spacing: -0.31px;
      line-height: 29px;
      &_radio { margin-bottom: 29px; }
    }

    .label-radio {
      color: #000;
      font-size: 19px;
      font-weight: 500;
      letter-spacing: -0.31px;
      line-height: 30px;
      font-family: var(--root-font-family);
    }
  }

  &-output {
    position: relative;
    width: 100%;
    max-width: 353px;
    margin-top: 213px;
    &__img {
      position: absolute;
      top: -253px;
      left: 0;
      height: 243px;
      width: 100%;
      max-width: 353px;
      svg { width: 100%; height: auto; }
    }

    &__title {
      color: #000;
      font-size: 19px;
      font-weight: 500;
      letter-spacing: -0.31px;
      line-height: 29px;
    }
    &__amount {
      color: var(--secondary-color);
      font-size: 35px;
      font-weight: bold;
      letter-spacing: -0.56px;
      line-height: 53px;
    }
    &__btn.mat-btn {
      margin-top: 25px;
      padding: 18px 0 20px;
      width: 100%;
      max-width: 249px;
      line-height: 25px;
      border-radius: 10px;
    }
  }

}

@media only screen and (max-width: 1099px) {
  .main-calculator {
    margin: 0;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    border-left: none;
    border-right: none;
    padding-bottom: 23px;

    h4 { max-width: 345px; font-size: 24px; letter-spacing: -0.37px; line-height: 33px; margin-top: 30px; }

    &__container { flex-direction: column; align-items: center; }

    &-output {
      margin-top: 42px;
      &__img { display: none; }
      &__btn {
        &-container { display: flex; justify-content: center; }
      }
    }
  }
}
