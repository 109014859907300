app-pma,
pma-upload-coverage-proof,
pma-enrollment-complete,
pma-opt-in {
  width: 100%;
  min-height: 600px;
}

.tenant-boarding {

  .pma-row {
    margin-bottom: 15px;
    &.grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 15px;
      grid-row-gap: 15px;

      &.names {
        grid-template-columns: 2fr 1fr 2fr;
      }
    }
  }

  .section-title {
    margin-top: 5px;
  }

  &__button {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__errors {
    &:not(:empty) {
      margin: 1rem;
    }
    ul {
      list-style: initial;
      li {
        margin-left: 1.5rem;
      }
    }
  }

  &__wrap-get-renters-quote {
    position: absolute;
    left: calc(50% + 410px);
    top: 0;
    height: 100%;
    width: 230px;
  }

  &__get-renters-quote {
    position: sticky;
    top: 50px;
    left: 0;
    width: 230px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 4px;
    border: 1px solid #e9e9e9;
    text-align: center;
    box-sizing: border-box;
    z-index: 10;

    p {
      font-weight: bold;
      font-size: 14px;
    }

    .tenant-btn {
      margin-top: 10px;
    }
  }

  &__header {
    text-align: center;
    margin: 75px auto 0;
    max-width: 690px;
    font-size: 50px;
    color: #51337D;

    &_medium {
      font-size: 36px;
      font-weight: bold;
    }

    &_small {
      font-size: 26px;
      font-weight: bold;
    }
  }

  &__insurables {
    text-align: center;
    margin: 0 auto;
    max-width: 690px;
    font-size: 18px;
    color: #51337D;
    font-weight: bold;
  }

  &__wrap {
    max-width: 790px;
    width: 100%;
    margin: 30px auto;
    position: relative;
    z-index: 2;
  }

  &__cloud-container {
    padding: 40px 45px 20px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 2px 10px -1px #dcdde2;
    background: #fff;

    &__intro {
      margin: 15px 0 25px;
    }
  }

  &__text {
    font-size: 16px;

    &__line-height {
      line-height: 17px;
    }

    &_small {
      font-size: 14px;
      line-height: 15px;
    }

    &_thin {
      font-weight: 100;
    }

    &_bold {
      font-weight: bold;
    }

    &_center {
      text-align: center;
    }

    &_italic {
      font-style: italic;
    }

    &_underline {
      text-decoration: underline;
    }

    &_black {
      color: #44403F;
    }

    &_grey {
      color: #535353;
    }

    &_blue {
      color: #070BCF;
    }

    &_cyan {
      color: #2DB3E5;
    }

    &_gray {
      color: #4C4A49;
    }

    &_purple {
      color: #51337D;
    }
  }

  &__highlight {
    font-weight: 600;
  }

  &__master_policy_text {
    font-size: 15px;
    line-height: 16px;
    color: #535353;
    margin-bottom: 18px;
  }

  &-coverage-option {
    box-sizing: border-box;
    border: 1px solid #595959;
    padding: 15px;
    border-radius: 20px;
    position: relative;
    background: #fff;
    cursor: pointer;
    height: 365px;

    &_selected {
      border: 1px solid #50327C;
      box-shadow: 0 0 0 2px #50327C;
      background: #F2EFF6;
    }

    &:hover, &-highlighted {
      background: #F2EFF6;
    }

    &__hint {
      position: absolute;
      top: 10px;
      right: 5px;
      height: 20px;
      width: 20px;
      color: #555555;

      .mat-icon {
        font-size: 18px;
      }

      &__text {
        position: absolute;
        top: 10px;
        right: 10px;
        border: 1px solid #323232;
        padding: 5px 20px;
        background: #fff;
        font-size: 14px;
        width: 220px;
        display: none;
        z-index: 1;
      }

      &:hover {
        .tenant-boarding-coverage-option__hint__text {
          display: block;
        }
      }
    }

    &__content {
      font-size: 16px;
      line-height: 17px;
      font-weight: bold;
      text-align: center;
      color: #4C4A49;

      &__title {
        color: #51337D;
        font-size: 24px;
        line-height: 27px;
      }

      &__img {
        display: inline-block;
        margin: 5px 0 0;

        img {
          height: 90px;
          width: 90px;
        }
      }
    }

    &__view-coverages {
      margin-top: 2px;
      color: #0725BE;
      font-size: 12px;
      text-align: center;
      width: 100%;
      cursor: pointer;
    }
  }

  .tenant-btn {
    font-size: 13px;
    line-height: 14px;
    border-color: #51337D;
    background: #51337D;
    white-space: normal;
  }

  &_mt-5 { margin-top: 5px; }
  &_mt-10 { margin-top: 10px; }
  &_mt-15 { margin-top: 15px; }
  &_mt-20 { margin-top: 20px; }
  &_mt-25 { margin-top: 25px; }
  &_mt-35 { margin-top: 35px; }
  &_mt-45 { margin-top: 45px; }
  &_mt-65 { margin-top: 65px; }

  &_font-size-25 {
    font-size: 25px;
  }

  &_font-size-20 {
    font-size: 20px;
  }

  &_font-size-18 {
    font-size: 18px;
  }

  &_font-size-12 {
    font-size: 12px;
  }

  &_font-size-11 {
    font-size: 11px;
  }

  &_font-size-10 {
    font-size: 10px;
  }

  &__passwords {
    margin-top: 40px;
    border: 1px solid #535353;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 40px 20px;
    position: relative;

    &__label {
      position: absolute;
      background: #fff;
      color: #535353;
      top: -13px;
      left: 12px;
      width: 80px;
      text-align: center;
    }
  }

  &__complete-text {
    margin: 120px 0 140px;
    font-size: 26px;
  }
}

@media screen and (max-width: 1289px) {
  .tenant-boarding {
    &__wrap-get-renters-quote {
      position: sticky;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 10;
    }

    &__get-renters-quote {
      top: 0;
      left: 0;
      width: 100%;
      padding: 8px;

      .mat-btn.tenant-btn {
        padding: 12px;
      }
    }
  }
}


@media screen and (max-width: 600px) {
  .tenant-boarding {

    .pma-row {
      &.grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: .5rem;
        grid-row-gap: .5rem;
        &.names {
          grid-template-columns: 1fr;
        }
      }
    }

    &__cloud-container {
      padding: 16px;
    }

    &__header {
      margin: 0;
      font-size: 18px;
    }

    &__text_small {
      font-size: 12px;
    }

    &-coverage-option {
      padding: 5px;
      height: auto;

      &__content {

        &__img {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 40px;
            height: 40px;
          }
        }

        &__text {
          font-size: 12px;
          max-width: 50%;
          margin: 0 auto;
        }
      }
    }

    &_mt-5 { margin-top: 0; }
    &_mt-10 { margin-top: 0; }
    &_mt-15 { margin-top: 0; }
    &_mt-20 { margin-top: 0; }
    &_mt-35 { margin-top: 0; }
    &_mt-45 { margin-top: 0; }
    &_mt-65 { margin-top: 0; }

    &_font-size-25 {
      font-size: 13px;
    }

    &_font-size-20 {
      font-size: 12px;
    }

    &_font-size-18 {
      font-size: 11px;
    }

    &__complete-text {
      margin: 10px 0 30px;
      font-size: 16px;
    }

    .tenant-btn {
      padding: 20px;
      font-size: 13px;
    }

  }
}

